const scrollToTopButton = document.querySelector('.scroll-to-top');
let didScroll;
let lastScrollTop = 0;
const delta = 80;

window.addEventListener('scroll', function() {
	didScroll = true;
}, false);

setInterval(function() {
	if (didScroll) {
		scrollFunction();
		didScroll = false;
	}
}, 200);

const scrollFunction = () => {
	const st = window.pageYOffset || document.documentElement.scrollTop;
	// Make sure they scroll more than delta
	if (Math.abs(lastScrollTop - st) <= delta) {
		return;
	}
	if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
		scrollToTopButton.classList.add('show');
	} else if (st > lastScrollTop || window.scrollY < 200 || document.documentElement.scrollTop < 200) {
		scrollToTopButton.classList.remove('show');
	} else {
		scrollToTopButton.classList.add('show');
	}
	lastScrollTop = st <= 0 ? 0 : st;
};

scrollToTopButton.addEventListener('click', function() {
	window.scrollTo({top: 0, behavior: 'smooth'});
});

const hideScrollToTop = () => {
	if (document.documentElement.scrollHeight <= window.innerHeight) {
		scrollToTopButton.remove();
	}
};

window.addEventListener('load', hideScrollToTop, false);
