($ => {
	function changeValue(e, operation) {
		e.preventDefault();
		const wrapper = $(e.target).closest('.quantity-input-wrapper');
		const fieldID = $(e.target).data('field-id');
		const field = wrapper.find(`#${ fieldID }`);
		const min = (field.attr('min')) ? Number(field.attr('min')) : -99999999;
		const max = (field.attr('max')) ? Number(field.attr('max')) : 99999999;
		const step = (field.attr('step')) ? Number(field.attr('step')) : 1;
		const currentValue = Number(field.val());

		if (!isNaN(currentValue)) {
			if (operation === '+') {
				if (currentValue + step <= max) {
					field.val(currentValue + step);
					field.trigger('change');
				}
			} else if (operation === '-') {
				if (currentValue - step >= min) {
					field.val(currentValue - step);
					field.trigger('change');
				}
			}
		}
	}

	$(document).on('click', '.quantity-input-wrapper .quantity-button-plus, .quantity-input-wrapper .quantity-button-minus', e => {
		changeValue(e, $(e.target).val());
	});
})(window.jQuery);
