const tagCloud = document.querySelector('.tag-cloud');

if (tagCloud) {
	const facetWrapper = tagCloud.querySelector('.tag-cloud__facet-wrapper');
	const toggle = tagCloud.querySelector('.tag-cloud__toggle');

	toggle.addEventListener('click', () => {
		facetWrapper.classList.toggle('is-open');
		toggle.classList.toggle('is-open');
	});
}
