import A11yDialog from 'a11y-dialog';

const dialogToggle = document.querySelectorAll('.archive__filters__toggle-dialog');

if (dialogToggle.length > 0) {
	dialogToggle.forEach(toggle => {
		document.addEventListener('facetwp-loaded', function() {
			const parentWrapper = toggle.closest('.archive__filters__dialog-wrapper');

			// if .facetwp-facet is empty, hide the toggle button
			const facet = parentWrapper.querySelector('.facetwp-facet');

			if (facet) {
				if (facet.innerHTML === '') {
					parentWrapper.classList.add('d-none');
				} else {
					parentWrapper.classList.remove('d-none');
				}
			}
		});

		const dialogId = toggle.getAttribute('data-a11y-dialog-show');

		if (dialogId) {
			const dialog = document.getElementById(dialogId);

			if (dialog) {
				const a11yDialog = new A11yDialog(dialog, document.querySelector('.js-page'));

				a11yDialog.on('show', () => {
					toggle.classList.add('is-active');
				});

				a11yDialog.on('hide', () => {
					toggle.classList.remove('is-active');
				});

				document.addEventListener('facetwp-loaded', function() {
					// count how many filters are active in the dialog, then change the value of archive__filters__toggle-dialog__count in the toggle button to the number of active filters
					const activeFilters = dialog.querySelectorAll('.facetwp-checkbox.checked');

					if (activeFilters.length > 0) {
						toggle.querySelector('.archive__filters__toggle-dialog__count').classList.remove('is-hidden');
						toggle.querySelector('.archive__filters__toggle-dialog__count').innerHTML = activeFilters.length;
						toggle.classList.add('has-active-filters');
					} else {
						toggle.querySelector('.archive__filters__toggle-dialog__count').classList.add('is-hidden');
						toggle.classList.remove('has-active-filters');
					}
				});
			}
		}
	});
}

const sortDropdown = document.querySelector('.facetwp-sort, .facetwp-type-sort');

if (sortDropdown) {
	document.addEventListener('facetwp-loaded', function() {
		const select = sortDropdown.querySelector('select');

		if (select.value && select.value !== 'default') {
			sortDropdown.classList.add('has-sort-method');
		} else {
			sortDropdown.classList.remove('has-sort-method');
		}
	});
}
