($ => {
// handle dynamic stock information
	const $variationWrap = $('.single_variation_wrap');

	if ($variationWrap.length) {
		const $stockInfo = $('.product__add-to-cart .stock-info');
		const outOfStockText = $stockInfo.data('out-of-stock');
		const inStockText = $stockInfo.data('in-stock');

		$variationWrap.on('show_variation', function(event, variation) {
			// Fired when the user selects all the required dropdowns / attributes
			// and a final variation is selected / shown
			if (!!variation) {
				if (variation.is_in_stock) {
					$stockInfo.addClass('stock-info--is-in-stock');
					$stockInfo.removeClass('stock-info--is-out-of-stock');
					$stockInfo.html(inStockText);
				} else {
					$stockInfo.removeClass('stock-info--is-in-stock');
					$stockInfo.addClass('stock-info--is-out-of-stock');
					$stockInfo.html(outOfStockText);
				}
			}
		});
	}

	// coupon toggle
	$('body').on('click', '[toggle-cart-coupon]', () => {
		$('.cart-totals .coupon').toggleClass('is-active');
	});
})(window.jQuery);
