const searchInput = document.querySelector('.glossar__search--input');
const searchFilters = document.querySelectorAll('.glossar__filter--single');
const sections = document.querySelectorAll('.glossar__section');

if (!!searchInput && searchFilters.length && sections.length) {
	searchInput.addEventListener('keyup', function(e) {
		// search through the content of the sections html (filter html out)
		const searchValue = e.target.value.toLowerCase();
		if (searchValue !== '') {
			sections.forEach(section => {
				section.classList.add('glossar__section--hidden');

				// search for accordions
				const accordions = section.querySelectorAll('.accordion-item');
				accordions.forEach(accordion => {
					// check all .accordion-button and .glossar__single--title to match with searchValue
					const searchFields = accordion.querySelectorAll('.accordion-button, .glossar__single--title');

					accordion.classList.add('accordion--hidden');

					// loop through all searchFields and check if searchValue is in there
					searchFields.forEach(field => {
						if (field.innerHTML.toLowerCase().includes(searchValue)) {
							// if yes, show the section
							accordion.classList.remove('accordion--hidden');
						}
					});
				});

				const visibleAccordions = section.querySelectorAll('.accordion-item:not(.accordion--hidden)');
				if (visibleAccordions.length > 0) {
					section.classList.remove('glossar__section--hidden');
				} else {
					section.classList.add('glossar__section--hidden');
				}
			});
		} else {
			// if searchValue is empty, show all sections
			sections.forEach(section => {
				section.classList.remove('glossar__section--hidden');
				const accordions = section.querySelectorAll('.accordion-item');
				accordions.forEach(accordion => {
					accordion.classList.remove('accordion--hidden');
				});
			});
		}
	});
}
