(($) => {
	const numberOfPosts = 3;
	const $relatedPostsBlock = $('.related-posts--partner');
	const $relatedPosts = $relatedPostsBlock.find('.related-posts__item');
	const $button = $relatedPostsBlock.find('.related-posts__button-wrapper');

	// Function to update the visibility of the button
	function updateButtonVisibility() {
		const hiddenCount = $relatedPosts.filter('.d-none').length;
		if (hiddenCount > 0) {
			$button.removeClass('d-none');
		} else {
			$button.addClass('d-none');
		}
	}

	// Initially update the button visibility
	updateButtonVisibility();

	// Initially show the first `numberOfPosts` items
	$relatedPosts.slice(0, numberOfPosts).hide().removeClass('d-none').fadeIn();

	// Update button visibility after initial fade in
	updateButtonVisibility();

	// Click event to show more posts
	$button.on('click', function () {
		// Find the next set of hidden `.related-posts__item` and show them
		$relatedPosts.filter('.d-none').slice(0, numberOfPosts).hide().removeClass('d-none').fadeIn();

		// Update the button visibility after items are shown
		updateButtonVisibility();
	});
})(window.jQuery);
