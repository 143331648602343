const disruptorMobile = document.getElementById('disruptor-mobile');
const disruptor = document.getElementById('disruptor');
let disruptorHref = null;

if (disruptor) {
	disruptorHref = disruptor.getAttribute('href');
}

if (disruptorMobile) {
	disruptorMobile.addEventListener('click', () => {
		if (disruptorMobile.classList.contains('open')) {
			window.location.href = disruptorHref;
		}

		disruptorMobile.classList.add('open');

		setTimeout(() => {
			disruptorMobile.classList.remove('open');
		}, 3000);
	},
	);
}

