const searchButtons = document.querySelectorAll('.search-button');
const searchMask = document.querySelector('.search-mask');
const searchBackdrop = document.querySelector('.search-mask__backdrop');
const searchForm = document.querySelector('.search-mask__form');
const searchInput = searchMask.querySelector('.search-mask__input');
const searchSubmit = searchMask.querySelector('.search-mask__submit');

const searchResultForm = document.querySelector('.search-results__form');

// check if searchResultForm exists, if so, add event listener
if (searchResultForm) {
	const searchResultInput = searchResultForm.querySelector('.search-results__input');
	const searchResultSubmit = searchResultForm.querySelector('.search-results__submit');
	searchResultInput.addEventListener('input', () => {
		if (searchResultInput.value.length > 0) {
			searchResultSubmit.classList.add('active');
		} else {
			searchResultSubmit.classList.remove('active');
		}
	});

	// check if searchResultInput has value, if so, show submit button
	if (searchResultInput.value.length > 0) {
		searchResultSubmit.classList.add('active');
	}
}

let currentButton = null;
let positionInterval = null;

const openSearchMask = button => {
	currentButton = button;
	positionSearchMaskForm();
	searchMask.classList.toggle('active');
	searchInput.focus();
	positionInterval = setInterval(positionSearchMaskForm, 1000);
};

const closeSearchMask = () => {
	searchMask.classList.remove('active');
	currentButton = null;
	clearInterval(positionInterval);
};

const positionSearchMaskForm = () => {
	if (!currentButton) {
		return;
	}

	const buttonRect = currentButton.getBoundingClientRect();
	const buttonTop = buttonRect.top + window.scrollY - 4;
	const buttonLeft = buttonRect.left + window.scrollX - 16;
	const buttonWidth = buttonRect.width + 32;

	searchForm.style.top = `${ buttonTop }px`;
	searchForm.style.left = `${ buttonLeft }px`;
	searchForm.style.width = `${ buttonWidth }px`;
};

searchButtons.forEach(searchButton => {
	searchButton.addEventListener('click', e => {
		e.preventDefault();
		openSearchMask(e.target.closest('.search-button'));
	});
});

window.addEventListener('resize', positionSearchMaskForm);
window.addEventListener('scroll', positionSearchMaskForm);

searchBackdrop.addEventListener('click', closeSearchMask);

// detect if searchInput has value, if so, show submit button
searchInput.addEventListener('input', () => {
	if (searchInput.value.length > 0) {
		searchSubmit.classList.add('active');
	} else {
		searchSubmit.classList.remove('active');
	}
});
