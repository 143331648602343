import Plyr from 'plyr';

const wpVideos = document.querySelectorAll('.wp-block-video video:not(.is-initialized)');
const ytEmbeds = document.querySelectorAll('.is-provider-youtube > .wp-block-embed__wrapper:not(.is-initialized)');
const vimEmbeds = document.querySelectorAll('.is-provider-vimeo > .wp-block-embed__wrapper:not(.is-initialized)');

const options = {
	controls: [
		'play-large',
		'play',
		'mute',
		'progress',
		'current-time',
		'fullscreen',
	],
};

function makePlyr(video) {
	video.classList.add('is-initialized');
	new Plyr(video, options);
}

//  modify youtube embeds
for (const embed of ytEmbeds) {
	const {src} = embed.querySelector('iframe');

	embed.setAttribute('data-plyr-provider', 'youtube');
	embed.setAttribute('data-plyr-embed-id', src);
}

for (const video of [
	...wpVideos,
	...ytEmbeds,
	...vimEmbeds,
]) {
	makePlyr(video);
}
