import {
	disablePageScroll,
	enablePageScroll,
} from 'scroll-lock';
import * as basicScroll from 'basicscroll';
import A11yDialog from 'a11y-dialog';

const navigatorOverlayWrapper = document.querySelector('.navigator-overlay-wrapper');
const navigators = document.querySelectorAll('.navigator');
const navigatorButtons = document.querySelectorAll('.navigator-button');
const jsPage = document.querySelector('.js-page');
const closeIcons = document.querySelectorAll('.navigator-overlay__close');

if (navigatorOverlayWrapper && jsPage) {
	const navigatorDialog = new A11yDialog(navigatorOverlayWrapper, jsPage);

	navigatorDialog.on('show', () => {
		navigatorOverlayWrapper.classList.add('is-active');
		disablePageScroll(document.body);
	});

	navigatorDialog.on('hide', () => {
		navigatorOverlayWrapper.classList.remove('is-active');
		enablePageScroll(document.body);
	});

	closeIcons.forEach(closeIcon => {
		closeIcon.addEventListener('click', () => {
			navigatorDialog.hide();
		},
		);
	});

	navigatorButtons.forEach(navigatorButton => {
		navigatorButton.addEventListener('click', () => {
			navigatorDialog.show();
		},
		);
	});
}

navigators.forEach(navigator => {
	const spinny = navigator.querySelector('.navigator__element .circle');
	const back = navigator.querySelector('.navigator__back');
	const forward = navigator.querySelector('.navigator__forward');
	let rm = 0;

	if (navigator.classList.contains('navigator--scroll-rotate')) {
		const bs = basicScroll.create({
			elem: spinny,
			from: '0',
			to: 'bottom-top',
			direct: true,
			props: {
				'--r': {
					from: '0deg',
					to: '360deg',
				},
			},
		});
		bs.start();
	}

	back.addEventListener('click', () => {
		rm -= 1;
		spinny.style.setProperty('--rm', (rm * 45) + 'deg');
	});

	forward.addEventListener('click', () => {
		rm += 1;
		spinny.style.setProperty('--rm', (rm * 45) + 'deg');
	});
});

