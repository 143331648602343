
($ => {
	$(document).on('facetwp-loaded', function() {
		$('.facetwp-page').on('click', function() {
			const archiveFilters = document.querySelector('.archive__filters');

			if (archiveFilters) {
				$(document).on('facetwp-loaded', function() {
					archiveFilters.scrollIntoView({behavior: 'smooth'});
				});
			} else {
				window.scrollTo({top: 0, behavior: 'smooth'});
			}
		});
	});
})(window.jQuery);
