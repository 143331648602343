// manifest
import 'pwacompat/src/pwacompat';

// add better focus and input type detection on body element (for development)
// import 'what-input';

// components
import './components/aucorNavigation';
import './components/bootstrap';
import './components/footerHover';
import './components/footerMobileNav';
import './components/headerAnimation';
import './components/searchForm';
import './components/lottie';
import './components/quantityInput';
import './components/swiper';
import './components/video';
import './components/glossar';
import './components/navigator';
import './components/woocommerce';
import './components/filterDialog';
import './components/share-icons';
import './components/faqs';
import './components/pagerScrollTop';
import './components/scrollToTop';
import './components/likes';
import './components/disruptor';
import './components/loadMore';
import './components/tagCloud';
import './components/related-posts-partner';
import './components/login-gtm';

// infinte scroll for facetwp
// import './components/lazyLoading';
