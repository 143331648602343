import Swiper, {
	A11y,
	Navigation,
	Thumbs,
	Keyboard,
} from 'swiper';

/* wk vars */
const {wkScriptVars: wk} = window;

const imageSliders = document.querySelectorAll('.image-slider');

for (const slider of imageSliders) {
	const thumbs = slider.querySelector('.image-slider__thumbs-slider');

	let thumbsSlider;

	if (thumbs) {
		thumbsSlider = new Swiper(
			thumbs,
			{
				spaceBetween: 8,
				speed: 500,
				slidesPerView: 4,
				watchOverflow: false,
				centeredSlides: false,
				slideToClickedSlide: true,
				allowTouchMove: false,

				breakpoints: {
					1024: {
						spaceBetween: 16,
					},
				},
			},
		);
	}

	const imageSlider = new Swiper(
		slider.querySelector('.image-slider__images-slider'),
		{
			modules: [
				A11y,
				Navigation,
				Thumbs,
				Keyboard,
			],
			spaceBetween: 16,
			speed: 500,
			grabCursor: true,
			centeredSlides: true,
			slidesPerView: 1,
			a11y: {
				prevSlideMessage: wk.swiper.a11y.msg_prev_slide,
				nextSlideMessage: wk.swiper.a11y.msg_next_slide,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: true,
			},
			thumbs: {
				swiper: thumbsSlider,
			},
			navigation: {
				prevEl: slider.querySelector('.slider-navigation__button--prev'),
				nextEl: slider.querySelector('.slider-navigation__button--next'),
			},
		},
	);

	if (thumbs) {
		imageSlider.on('slideChange', function() {
			thumbsSlider.slideTo(this.realIndex - 1);
		});

		thumbsSlider.on('slideChange', function() {
			imageSlider.slideTo(this.realIndex + 1);
		});
	}
}

const testimonialSliders = document.querySelectorAll('.testimonial-slider');

for (const slider of testimonialSliders) {
	new Swiper(
		slider.querySelector('.testimonial-slider__container'),
		{
			modules: [
				A11y,
				Navigation,
				Keyboard,
			],
			spaceBetween: 32,
			speed: 500,
			grabCursor: true,
			slidesPerView: 1,
			autoHeight: true,
			a11y: {
				prevSlideMessage: wk.swiper.a11y.msg_prev_slide,
				nextSlideMessage: wk.swiper.a11y.msg_next_slide,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: true,
			},
			navigation: {
				prevEl: slider.querySelector('.slider-navigation__button--prev'),
				nextEl: slider.querySelector('.slider-navigation__button--next'),
			},
			breakpoints: {
				1024: {
					slidesPerView: 2,
				},
			},
		},
	);
}

const productSliders = document.querySelectorAll('.product-slider-wrapper');

for (const slider of productSliders) {
	new Swiper(
		slider.querySelector('.product-slider'),
		{
			modules: [
				A11y,
				Navigation,
				Keyboard,
			],
			spaceBetween: 12,
			speed: 700,
			slidesPerView: 2,
			slidesPerGroup: 2,
			autoHeight: false,
			watchOverflow: true,
			a11y: {
				prevSlideMessage: wk.swiper.a11y.msg_prev_slide,
				nextSlideMessage: wk.swiper.a11y.msg_next_slide,
			},
			keyboard: {
				enabled: true,
				onlyInViewport: true,
			},
			navigation: {
				prevEl: slider.querySelector('.slider-navigation__button--prev'),
				nextEl: slider.querySelector('.slider-navigation__button--next'),
			},
			breakpoints: {
				420: {
					slidesPerView: 2,
					slidesPerGroup: 2,
					spaceBetween: 16,
				},
				640: {
					slidesPerView: 3,
					slidesPerGroup: 3,
					spaceBetween: 12,
				},
				768: {
					slidesPerView: 3,
					slidesPerGroup: 3,
					spaceBetween: 24,
				},
				1024: {
					slidesPerView: 4,
					slidesPerGroup: 4,
					spaceBetween: 24,
				},
				1200: {
					slidesPerView: 4,
					slidesPerGroup: 4,
					spaceBetween: 32,
				},
			},
		},
	);
}

