const {theme_url} = window.wkScriptVars;

const loaderUrl = `${ theme_url }/assets/src/images/loader.svg`;
const loaderImage = `<img src="${ loaderUrl }" class="post-grid__loading-animation">`;

const handleLoadMore = postType => {
	const postGrids = document.querySelectorAll('[data-post-grid="' + postType + '"]');

	if (postGrids.length > 0) {
		postGrids.forEach(postGrid => {
			const loadMoreButton = postGrid.querySelector('[data-post-grid-load-more]');
			const postsPerLoad = postGrid.dataset.postsPerLoad;
			const searchQuery = window.location.search.split('=')[1];

			if (loadMoreButton === null) {
				return;
			}

			loadMoreButton.addEventListener('click', () => {
				const loaderWrapper = postGrid.querySelector('[data-post-grid-loader]');
				const maxPosts = parseInt(postGrid.dataset.maxPosts);

				loadMoreButton.setAttribute('disabled', true);
				loaderWrapper.innerHTML = loaderImage;

				fetch('/wp-json/wk/v2/loadPosts', {
					headers: {
						'Access-Control-Allow-Origin': '*',
						'Content-Type': 'application/json; charset=utf-8',
					},
					mode: 'cors',
					cache: 'no-cache',
					credentials: 'same-origin',
					body: JSON.stringify({
						postTypes: postType,
						postsPerPage: postsPerLoad,
						s: searchQuery,
						currentPostCount: postGrid.querySelectorAll('.search-result__item').length,
					}),
					method: 'POST',
				})
					.then(response => {
						return response.json();
					})
					.then(json => {
						if (json.success) {
							postGrid.querySelector('.search-results__grid').insertAdjacentHTML('beforeend', json.data.html);

							if (json.data.postCount >= maxPosts) {
								loadMoreButton.remove();
								loaderWrapper.remove();
							} else {
								loadMoreButton.removeAttribute('disabled');
								loaderWrapper.innerHTML = '';
							}
						}
					});
			});
		});
	}
};

handleLoadMore('product');
handleLoadMore('service');
handleLoadMore('post');
handleLoadMore('partner,page,industry');
