document.querySelectorAll('.footer-navigation__toggle').forEach(button => {
	button.addEventListener('click', function() {
		const content = button.nextElementSibling;
		const isCollapsed = content.getAttribute('aria-hidden') === 'true';

		if (isCollapsed) {
			button.setAttribute('aria-expanded', 'true');
			content.setAttribute('aria-hidden', 'false');
		} else {
			button.setAttribute('aria-expanded', 'false');
			content.setAttribute('aria-hidden', 'true');
		}
	});
});
