import Cookies from 'js-cookie';

const initLikes = () => {
	const likeButtons = document.querySelectorAll('.wk-likes__wrapper');

	if (likeButtons.length > 0) {
		likeButtons.forEach(likeButton => {
			const button = likeButton.querySelector('.wk-likes__button');
			const likeCount = likeButton.querySelector('.wk-likes__count-number');
			const postId = button.dataset.id;
			const postType = button.dataset.type;

			const fetchOptions = {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Content-Type': 'application/json; charset=utf-8',
				},
				mode: 'cors',
				cache: 'no-cache',
				credentials: 'same-origin',
				body: JSON.stringify({
					post_id: postId,
					post_type: postType,
				}),
				method: 'POST',
			};

			if (Cookies.get(`liked-${ postType }-${ postId }`)) {
				button.classList.add('wk-likes__button--liked');

				// if timestamp of likeCount is older than the timestamp of the cokkie, add 1 to the likeCount
				if (likeCount.dataset.time < Cookies.get(`liked-${ postType }-${ postId }`)) {
					likeCount.innerHTML = parseInt(likeCount.dataset.likes) + 1;
				}
			}

			likeButton.addEventListener('click', () => {
				if (!Cookies.get(`liked-${ postType }-${ postId }`)) {
					fetch('/wp-json/wk/v2/like', fetchOptions)
						.then(response => {
							if (response.status !== 400) {
								const timestamp = Math.floor(Date.now() / 1000);

								Cookies.set(`liked-${ postType }-${ postId }`, timestamp, {expires: 500});
								button.classList.add('wk-likes__button--liked');
								likeCount.innerHTML = parseInt(likeCount.dataset.likes) + 1;
							}
						});
				} else {
					fetch('/wp-json/wk/v2/unlike', fetchOptions)
						.then(response => {
							if (response.status !== 400) {
								if (likeCount.dataset.time > Cookies.get(`liked-${ postType }-${ postId }`)) {
									likeCount.innerHTML = parseInt(likeCount.dataset.likes) - 1;
								} else {
									likeCount.innerHTML = likeCount.dataset.likes;
								}

								Cookies.remove(`liked-${ postType }-${ postId }`);
								button.classList.remove('wk-likes__button--liked');
							}
						});
				}
			});
		});
	}
};

document.addEventListener('DOMContentLoaded', () => {
	initLikes();
});
