const mainNav = document.querySelector('.footer-row--main');
const mainBavItems = mainNav.querySelectorAll('.footer-navigation__link');

mainBavItems.forEach(function(item) {
	item.addEventListener('mouseover', function() {
		item.classList.toggle('faded--active');

		mainBavItems.forEach(function(nonActiveItems) {
			nonActiveItems.classList.toggle('faded');
		});
	});

	item.addEventListener('mouseout', function() {
		item.classList.toggle('faded--active');

		mainBavItems.forEach(function(nonActiveItems) {
			nonActiveItems.classList.toggle('faded');
		});
	});
});

