import tippy from 'tippy.js';

const elements = document.querySelectorAll('.share-icons');

for (const element of elements) {
	const btnPrint = element.querySelector('button.is-print');
	const btnCopy = element.querySelector('button.is-copy');

	btnPrint.addEventListener('click', e => {
		window.print();
		e.preventDefault();
	});
	const flashTipTimeouts = [];
	const tip = tippy(btnCopy, {
		content: wkScriptVars.tip_copied,
		allowHTML: true,
		trigger: 'manual',
		offset: [0, 8],
		placement: 'bottom',
	});
	btnCopy.addEventListener('click', e => {
		const {url} = e.currentTarget.dataset;

		navigator.clipboard.writeText(url).then(() => {
			flashTipTimeouts.forEach(timeout => clearTimeout(timeout));
			tip.show();
			flashTipTimeouts.push(setTimeout(() => tip.hide(), 1500));
		});
		e.preventDefault();
	});
}
