(function($) {
	const updateSearchFilter = searchValue => {
		searchValue = searchValue.toUpperCase();

		const $searchElements = $('#faq-grid .accordion-item');
		const $emptySearch = $('.archive__empty-search');
		const $headings = $('h2.archive__category-title');

		if ($searchElements.length) {
			$searchElements.each(function() {
				const $element = $(this);

				if ($(this).text().toUpperCase().indexOf(searchValue) > -1) {
					$element.show();
					$element.removeClass('hidden');
				} else {
					$element.hide();
					$element.addClass('hidden');
				}
			});

			if ($('#faq-grid .accordion-item').filter(':visible').length) {
				$emptySearch.hide();
			} else {
				$emptySearch.show();
			}

			$headings.each(function() {
				const $heading = $(this);

				if ($heading.nextUntil('h2.archive__category-title', '.accordion-item:not(.hidden)').length === 0) {
					$heading.hide();
				} else {
					$heading.show();
				}
			});
		}
	};

	if ($('input#search')) {
		$('input#search').on('keyup search', function() {
			updateSearchFilter($(this).val());
		});
	}
}(window.jQuery));
