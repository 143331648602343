import LottiePlayer from 'lottie-web';

const initLottie = () => {
	// initialize all Lottie animations
	const players = document.querySelectorAll(
		'.lottie-player:not(.is-initialized)',
	);
	players.forEach(player => {
		// path is required
		if (!player.hasAttribute('data-path')) {
			return;
		}

		// initialize instance
		player.classList.add('is-initialized');
		const lottie = LottiePlayer.loadAnimation({
			container: player,
			renderer: 'svg',
			path: player.getAttribute('data-path'),
			loop: (player.getAttribute('data-loop') ?? 'true') === 'true',
			autoplay:
                (player.getAttribute('data-autoplay') ?? 'true') === 'true',
		});

		lottie.addEventListener('DOMLoaded', () => {
			// custom random setting (starts animation at random frame)
			if ((player.getAttribute('data-random') ?? 'false') === 'true') {
				const frames = lottie.getDuration(true);
				const randomFrame = Math.floor(Math.random() * frames);
				if (lottie.isPaused) {
					lottie.goToAndStop(randomFrame, true);
				} else {
					lottie.goToAndPlay(randomFrame, true);
				}
			}

			if (player.getAttribute('data-static') === 'true') {
				lottie.goToAndStop(100, true);
			} else {
				// custom reveal setting
				new IntersectionObserver(
					entries => {
						if (entries[0].intersectionRatio >= 0.5) {
							lottie.play();
						}
						if (entries[0].intersectionRatio === 0) {
							lottie.goToAndStop(0, true);
						}
					},
					{threshold: [0, 0.5, 1]},
				).observe(player);
			}
		});
	});
};

document.addEventListener('DOMContentLoaded', initLottie);
