(function($) {
	const header = $('.header');

	// Hide Header on on scroll down
	let didScroll;
	let lastScrollTop = 0;
	const navbarHeight = $('header').outerHeight();
	const delta = 48;

	$(window).scroll(function() {
		didScroll = true;
	});

	setInterval(function() {
		if (didScroll) {
			hasScrolled();
			didScroll = false;
		}
	}, 150);

	function hasScrolled() {
		const st = $(this).scrollTop();

		// Make sure they scroll more than delta
		if (Math.abs(lastScrollTop - st) <= delta) {
			return;
		}

		// If they scrolled down and are past the navbar, add class .nav-up.
		// This is necessary so you never see what is "behind" the navbar.
		if (st > lastScrollTop) {
			// Scroll Down
			header.removeClass('scrolled-up').addClass('scrolled-down');
			header.removeClass('header--small');
		} else if (st + $(window).height() < $(document).height()) {
			header.removeClass('scrolled-down').addClass('scrolled-up');
			if (st < navbarHeight) {
				header.removeClass('scrolled-up');
				if (header.hasClass('header--initial-small')) {
					header.addClass('header--small');
				}
			}
		}

		lastScrollTop = st;
	}
}(window.jQuery));
